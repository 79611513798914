@import (reference) '~/stylesheets/core.less';
@import (reference) '~/components/common/vendors/withDraggablePhoto.less';

@import './videoThumbnail.less';

.video-gallery__video-gallery-card {
  text-align: left;
  margin-bottom: @secondary-spacing;

  .video-thumbnail-content {
    border: 1px solid @color-white-100;
    background-color: @color-black-005;
    img.video-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .video-meta {
    .video-title,
    .video-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: @screen-xs-max) {
        padding-left: @tertiary-spacing;
      }
    }

    .video-title {
      margin-top: @tertiary-spacing;
      color: @color-navy;
      font-weight: @font-weight-semibold;
    }
    .video-type {
      color: @color-black;
      font-weight: @font-weight-light;
    }
  }

  &-upload-enabled {
    .video-thumbnail-content {
      background-color: @color-white;
      border: 1px dashed @color-primary-teal;
    }
  }
  &-upload-required {
    .video-thumbnail-content {
      border: 1px dashed @color-red;
    }
  }
  &-upload-enabled,
  &-placeholder {
    border-top: @drag-border-width solid transparent;
    .video-meta {
      // This is title/text placeholder to keep the cards the same size
      visibility: hidden;
      @media (max-width: @screen-xs-max) {
        display: none;
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    // On the xs screen, don't pad around the image
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .video-gallery__video-gallery-card-button-container {
    right: 10px;
    top: 0;
    button {
      margin-top: @tertiary-spacing;
    }
  }

  .video-gallery__video-gallery-card-controls-container,
  .video-gallery__video-gallery-card-button-container {
    @media (min-width: @screen-sm-min) {
      visibility: hidden;
    }

    position: absolute;
    display: flex;
    flex-direction: column;
    button {
      background-color: @color-white;
      color: @color-primary-teal;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      font-size: @zui-fontsize-regular;
      border: none;
      padding: 0;
      &:hover {
        color: @color-hover-teal;
      }
    }
  }
  .video-gallery__video-gallery-card-controls-container {
    left: 10px;
    bottom: 10px;
    font-size: @zui-fontsize-regular;
    .zola-ui-icon-play-2 {
      // special case for this icon.  it _is_ centered with the other styles, but because so much of the "weight" of the icon is on the left, it doesn't _look_ centered
      margin-left: 3px;
    }
  }

  &:hover {
    .video-gallery__video-gallery-card-controls-container,
    .video-gallery__video-gallery-card-button-container {
      visibility: visible;
    }
  }
}

.video-gallery-player {
  width: 900px;
  max-width: 100%;
}
