@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';

.availability {
  .mobile({
    margin-left: 0;
  });
  :global {
    .vendors-availability__calendar {
      display: block;
      margin: 0 auto;
      font-size: @zui-fontsize-smaller;
    }
  }

  &.availabilityV2 {
    .howTo {
      margin-top: @spacing-lg;
      margin-left: @spacing-sm;
      margin-bottom: @spacing-md;
      .hed {
        .small-heavy();
      }
      .list {
        list-style: initial;
        margin-left: 25px;
        margin-top: @spacing-xxs;
        .listItem {
          line-height: @zui-fontsize-large;
        }
      }

      .newList {
        padding: 0;
      }
    }
    .ctas {
      margin-top: @spacing-xs;
      margin-left: @spacing-sm;
      .btn {
        margin-bottom: @spacing-md;
        border: none;
        display: flex;
        text-transform: none;
        &:hover {
          background-color: @color-transparent;
        }
        .icon {
          margin-right: @spacing-md;
        }
        .btnText {
          text-align: left;
        }
      }
    }
  }
}

.day {
  color: @color-text-gray;
  padding: 4px 5px 3px;
  border: 0;
  border-radius: 50%;
  outline: none;
  font-weight: @font-weight-regular;
  &:active {
    background-color: @color-black-100 !important;
    border-color: @color-black-100;
    color: @color-white !important;
  }
  &:hover {
    background-color: @color-transparent !important;
    border: 2px solid @color-black-100;
    padding: 2px 3px 1px;
  }
}

.today {
  color: @color-black;
}

.booked {
  text-decoration: line-through;
  background-color: @color-black-015;
  color: @color-text-gray;
  font-weight: @font-weight-regular;
  &:active {
    border-color: @color-black-100;
  }
  &:hover {
    background-color: @color-black-015 !important;
  }
}

.root {
  display: block;
  margin: 0 auto;
  font-size: @zui-fontsize-smaller;

  --rdp-cell-size: 28px;
}

.months {
  min-height: 300px;
}

.caption {
  text-align: center;
}

.captionLabel {
  && {
    margin-bottom: @secondary-spacing;
    font-weight: @font-weight-regular;
    font-size: 16px;
  }
}

.weekday {
  padding-left: 0;
  padding-right: 0;

  abbr {
    text-decoration: none;
  }
}
