@import (reference) '~/stylesheets/core.less';

@spacing-64: 64px;
@spacing-48: 48px;

.unclaimedLearnMoreModal {
  display: flex;
  flex-direction: column;
  gap: @spacing-24;
  max-width: 536px;
  padding: @spacing-64 @spacing-48 @spacing-48;

  .mobile({
    gap: @spacing-32;
    max-height: 100vh;
    overflow: scroll;
    padding: @spacing-64 @spacing-24 @spacing-24;
  });

  .heading {
    h1 {
      margin-bottom: @spacing-8;

      .mobile({
        margin-bottom: @spacing-16;
      });
    }

    p {
      color: @color-black-050;
    }
  }

  .favoriteButton {
    button {
      height: 56px;
      background-color: @color-black-100;
      color: @color-white-100;

      &:hover {
        background-color: @color-black-075;
      }

      // This is necessary to override the "heart-empty-dark-outline" asset the full-width button uses
      & > div {
        width: 44px;
        height: 44px;
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-empty-white-outline.svg') !important;
      }

      &[class*='selected'] > div {
        background-image: url('~@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg') !important;
      }
    }
  }

  hr {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid @color-black-015;
  }
}
