@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.reviewsSection {
  .bullet {
    color: @color-black-075;
    font-size: 6px; // stylelint-disable-line scale-unlimited/declaration-strict-value
  }

  .reviewsSectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: @spacing-24;

    .heading {
      font-size: @zui-fontsize-large;
      font-weight: @font-weight-bold;
    }
  }

  .reviewsSectionHeaderCta {
    .not-mobile({
      display: none;
    });
  }

  .writeReview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @spacing-8;
    padding: @spacing-32;
    border-radius: @spacing-16;
    background-color: @color-black-005;
    font-size: @zui-fontsize-small;
    font-weight: @font-weight-semi-bold;
  }
}

.reviewsContent {
  .reviewsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: @spacing-24;
  }

  .reviewsHeaderStars {
    display: flex;
    gap: @spacing-xxs;
  }

  .reviewsHeaderStarsText {
    font-weight: @font-weight-semi-bold;
    .mobile({
      font-size: @zui-fontsize-small;
    });
  }

  .reviewsHeaderCta {
    .mobile({
      display: none;
    });
  }

  .reviews {
    display: flex;
    flex-direction: column;
    gap: @spacing-24;
  }

  .review {
    display: flex;
    flex-direction: column;
    gap: @spacing-8;
    border-top: 1px solid @color-black-005;
    padding-top: @spacing-24;

    .title {
      font-size: @font-size-h4;
      font-weight: @font-weight-bold;
    }

    .rating {
      display: flex;
      align-items: center;
      gap: @spacing-8;
      color: @color-black-050;
      font-size: @zui-fontsize-smaller;

      .mobile({
        flex-direction: column;
        align-items: flex-start;
        gap: @spacing-4;
      });

      .stars {
        display: flex;
        align-items: center;
        gap: @spacing-8;

        .verified {
          display: flex;
          align-items: center;
          gap: @spacing-4;

          .icon {
            color: @color-black-050;
          }
        }
      }

      .info {
        display: flex;
        align-items: center;
        gap: @spacing-8;

        .mobile({
          .bullet:first-child {
            display: none;
          }
        });
      }
    }

    .text {
      white-space: pre-line;
    }

    .reviewGallery {
      margin-top: 16px;
    }
  }

  .response {
    display: flex;
    flex-direction: column;
    gap: @spacing-8;
    margin-left: @spacing-24;

    .responseInfo {
      display: flex;
      align-items: center;
      gap: @spacing-8;
      margin-top: @spacing-8;
      color: @color-black-050;
      font-size: @zui-fontsize-smaller;
    }

    .responseText {
      color: @color-black-100;
      white-space: pre-line;
    }
  }

  .showAllButton {
    margin-top: @spacing-24;
    border-top: 1px solid @color-black-005;
    padding-top: @spacing-24;
  }
}

.yelpContent {
  .yelpHeader {
    display: flex;
    align-items: center;
    gap: @spacing-8;
    margin-bottom: @spacing-32;

    .yelpAverageRating {
      img {
        width: 96px;
      }
    }
  }

  .yelpReviews {
    display: flex;
    flex-direction: column;
    gap: @spacing-24;
    margin-bottom: @spacing-24;
  }

  .yelpReview {
    display: flex;
    flex-direction: column;
    gap: @spacing-8;

    .yelpRating {
      display: flex;
      align-items: center;
      gap: @spacing-8;
      color: @color-black-050;
      font-size: @zui-fontsize-smaller;

      .mobile({
        flex-direction: column;
        align-items: flex-start;
        gap: @spacing-4;
      });

      .yelpStars {
        display: flex;
        align-items: center;
        gap: @spacing-8;
      }

      .yelpInfo {
        display: flex;
        align-items: center;
        gap: @spacing-8;

        .mobile({
          .bullet:first-child {
            display: none;
          }
        });
      }
    }

    .yelpText {
      color: @color-black-100;
      font-size: @zui-fontsize-small;
      white-space: pre-line;
    }

    .yelpReadMoreLink {
      text-transform: none;
      white-space: nowrap;
    }
  }

  .yelpViewAllButton {
    gap: @spacing-8;
  }
}
