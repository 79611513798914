@import (reference) '~/stylesheets/core.less';

#marketplace-vendors-storefront-video-gallery {
  .video-gallery__top-divider,
  .video-gallery__bottom-divider {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .video-gallery__heading {
    justify-content: center;
  }
}

.marketplace-video-gallery-wrapper {
  .slick-slide {
    div:first-child {
      width: 100%;
    }
  }
  .carousel__container {
    .slick-dots {
      margin-top: 0;
      text-align: center;
    }
  }
}
