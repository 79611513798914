@import (reference) '~/stylesheets/core.less';

.unclaimedClaimModule {
  margin-bottom: 24px;

  .not-desktop({
    margin-bottom: 32px;
  });

  .heading {
    font-weight: @font-weight-semi-bold;
  }

  p {
    margin-top: 4px;
    line-height: 20px; // Override the storefront detail page default
  }
}
