@import (reference) '~/stylesheets/core.less';

.faqsSection {
  margin-bottom: @spacing-lg;
  position: relative;
  .itemCount {
    margin-top: 8px;
  }
}

.sectionHeading {
  width: 100%;
}

.desktopInquiryButton {
  // absolutely positioned because the entire header of the section is a button, and buttons cannot be nested
  position: absolute;
  top: 56px;
  right: 32px;
  .not-desktop({
    display: none !important;
  });
}

.faqsSectionItems {
  margin-top: 20px;

  section:last-of-type {
    border-bottom: none;
  }
}

.mobileOnly {
  width: 100%;
  margin-top: 16px;
  .desktop({
    display: none !important;
  });
}
