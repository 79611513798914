@import (reference) '~/stylesheets/core.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.storefrontStickyInfo {
  align-items: center;
  background-color: @color-white;
  border-top: 1px solid @color-black-030;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  min-height: 70px;
  padding: 8px 0;
  position: fixed;
  width: 100vw;
  z-index: @zIndex-content-top;

  .content-container-left-padding();
  .content-container-right-padding();

  .tablet({
    gap: 12px;

    // V3 design system rule brought in early
    padding-left: 56px;
    padding-right: 56px;
  });

  .actionNote {
    display: flex;
    justify-content: center;
    .tablet({
      justify-content: flex-end;
    });
  }

  .actionButtons {
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: center;
    justify-content: center;

    .shareFavorite {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }
}

.firstMovePrompt,
p.rapidRepeatText {
  .mobile({
    text-align: center;
  });
}

.firstMoveExpiration {
  display: block;
  text-align: center;
}

.ignoreButton,
.inquiryButton {
  flex-grow: 1;
}

.unclaimedModule {
  margin-bottom: unset;
  padding: unset;
  border-radius: unset;
  border: unset;
  flex-direction: row-reverse;
  width: 100%;
}
