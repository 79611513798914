@import (reference) '~/stylesheets/core.less';

.infoSection {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  border-radius: 16px;
  border: 2px solid @color-cove-100;

  .icon {
    color: @color-cove-100;
  }
}
