@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.savedSearchesButton {
  position: relative;
  display: inline-block;

  .button {
    font-weight: @font-weight-medium;
    text-transform: none;
  }

  .searches {
    @media (max-width: 1200px) and (min-width: 992px) {
      display: none;
    }
  }
}
