@import (reference) '~/stylesheets/core.less';

.unclaimedDisclaimerSection {
  margin-top: @spacing-8;
  margin-bottom: -2px;

  span.tooltipIcon {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: @color-black-050;
    gap: @spacing-4;

    :global {
      .tooltip__container {
        position: absolute;
        bottom: 33px;
        z-index: @zIndex-content-top;
        font-size: @zui-fontsize-small;
      }
    }
  }
}
