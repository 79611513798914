@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.imageContainer {
  position: absolute;
  top: @spacing-md;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.imageRelativeContainer {
  position: relative;
}

.image {
  width: 134px;
  height: 134px;
  border-radius: 8px;
  box-shadow: @default-box-shadow;
  position: relative;
}

.background {
  position: absolute;
  background-color: @color-background-jewel-8;
  width: 100%;
  height: 102px;
  top: 0;
  left: 0;
}

.leftHeart {
  position: absolute;
  top: 0;
  left: -70px;
  width: 146px;
  height: 146px;
  transform: rotate(-24.49deg);
}

.rightHeart {
  position: absolute;
  top: -23px;
  right: -59px;
  width: 120px;
  height: 120px;
  transform: rotate(28.04deg);
}
