@import (reference) '~/stylesheets/core.less';
@import '~/stylesheets/common/_topNav.less';

.marketplace__edit-storefront-layout {
  min-height: 80vh;
  background-color: @color-background-gray;
  h1 {
    color: @color-text-black;
  }
  h3 {
    font-weight: @font-weight-bold;
  }
  .vendors-storefront__images-header {
    text-align: center;
    margin-bottom: 10px;
  }
  .vendors-storefront__helper-text {
    color: @color-secondary-gray;
    font-size: @font-size-p-small-mobile;
    text-align: center;
    margin-bottom: 30px;
  }
  .vendor-storefront__section-title {
    max-width: 650px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .edit-storefront__required-container {
    position: relative;
    .edit-storefront__required {
      position: absolute;
      color: @color-red;
      left: 2px;
      top: -20px;
    }
  }
  .edit-storefront__nav {
    .marketplace-top-nav();
  }
  .edit-storefront__container {
    text-align: center;
    margin-top: 125px;
    padding-bottom: 110px;
    @media (max-width: @screen-xs-max) {
      margin-top: 100px;
    }
  }
  .edit-storefront__add-link {
    text-align: right;
  }
  .vendors-storefront__edit-row {
    margin-top: @tertiary-spacing;
    &:first-child {
      margin-top: 0;
    }
  }
  .edit-storefront__form {
    text-align: left;
    max-width: 650px;
    width: 100%;
    .edit-storefront__price-range {
      display: flex;
      align-items: center;
      .render-field {
        max-width: 130px;
      }
    }
  }
  .edit-storefront__label {
    color: @color-black;
    font-size: @font-size-h6;
    font-weight: @font-weight-bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  .edit-storefront__simple-progress-bar {
    position: fixed;
    top: 70px;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .banner-position-override {
    position: relative;
    top: 70px;
  }
}
