@import (reference) '~/stylesheets/core.less';

.container {
  padding: @spacing-lg;

  .title {
    margin-bottom: @spacing-sm;
  }
  .description {
    max-width: 488px;
  }
  .dismiss {
    display: flex;
    justify-content: end;
    margin-top: @spacing-lg;
  }
}
