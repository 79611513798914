@import (reference) '~/stylesheets/core.less';

.single-image-upload {
  position: relative;
  height: 100%;
  .single-image-upload {
    &__upload-zone {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      .v2-button {
        min-height: 44px;
      }
      .upload-pill {
        position: absolute;
        top: 10px;
        left: 10px;
        border-color: @color-navy;
        color: @color-navy;
      }
    }
    &__instructions {
      width: 450px;
      max-width: 100%;
      color: @color-primary-teal;
      font-weight: @font-weight-bold;
      text-align: center;
    }
    &__button-container {
      position: absolute;
      right: 10px;
      top: 0;
      display: flex;
      flex-direction: column;
      .single-image-upload__button {
        background-color: @color-white;
        color: @color-primary-teal;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        border: none;
        font-size: 20px;
        padding: 0;
        margin-top: 10px;
        &:hover {
          color: @color-hover-teal;
        }
      }
    }
  }
  &__photo--edit {
    border: 1px dashed @color-primary-teal;
    background-color: @color-highlight-blue;
  }
  &__photo--preview {
    background: @color-secondary-background-gray center center no-repeat;
    background-size: cover;
    &:hover {
      .darken-on-hover(0.1);
    }
  }
}
