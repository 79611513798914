@import (reference) '~/stylesheets/core.less';

.containerNormal {
  text-align: left;

  .inputWrapper,
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .input {
    background: none;
    border: none;
    width: 83%;
    line-height: 100%;
  }

  box-sizing: border-box;
  height: 44px;
  width: 100%;
  background-color: @color-white-100;
  border: 1px solid @color-black-030;
  border-radius: @border-radius;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;

  @media (max-width: @screen-xs-max) {
    height: 52px;
  }

  &.focused {
    border: 1px solid @color-black-100;
  }

  .clearSearchIcon {
    cursor: pointer;
    @media (hover: hover) {
      visibility: hidden;
    }
  }

  &:hover {
    .clearSearchIcon {
      visibility: visible;
    }
  }
}

.noInputBorder.containerNormal {
  border: none;
  width: 100%;

  .mobile({
    border: @input-border;
  });
}
