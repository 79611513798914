@import (reference) '~/stylesheets/core.less';

.socialLink {
  margin-right: 0.25em;
  margin-left: 0.25em;
  color: @color-secondary-gray;
  font-size: @zui-fontsize-regular;
  text-decoration: none;
  &:hover {
    color: @link-hover-color;
    text-decoration: none;
  }
}
