@import (reference) '~/stylesheets/core.less';

.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  list-style-type: disc;

  .mobile({
    grid-template-columns: 1fr;
  });
}

.item {
  margin-left: 1em;
  font-size: @zui-fontsize-small;
  line-height: @zui-fontsize-large;
}
