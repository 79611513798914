@import (reference) '~/stylesheets/core.less';

.overlaySearchBar {
  display: flex;
  justify-content: center;
  position: relative;
}

.verticallyStacked {
  display: flex;
  flex-direction: row;
  gap: @spacing-md;
  margin: 0;

  .mobile({
    width: 100%;
  });
}
