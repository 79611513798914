.requiredVendorsSection {
  .vendorCard {
    width: 100%;
  }

  :global {
    .marketplace__required-vendors-section .marketplace__see-all-drawer .container {
      padding-top: 0 !important;
    }
  }
}
