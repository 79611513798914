@import (reference) '~/stylesheets/core.less';

@color-light-outline-gray: #ededed;
@icon-size: 40px;

.menu-card__body {
  .menu-card__header {
    margin-top: -10px;
    .menu-card__icon {
      display: inline-block;
      float: left;
      width: @icon-size;
      height: @icon-size;
      margin-right: 15px;
    }
    .menu-card__price-range {
      display: inline-block;
      color: @color-black;
      font-size: @font-size-h5;
      font-weight: @font-weight-bold;
      line-height: @icon-size;
    }
  }
  .menu-card__body {
    overflow: hidden;
    .menu-card__name {
      font-weight: @font-weight-medium;
    }
    .menu-card__description {
      white-space: pre-line;
    }
    .menu-card__options {
      margin-left: 1.1em;
      list-style: disc outside;
      color: @color-black;
      font-size: @font-size-p-small-mobile;
    }
  }
  .menu-card__footer {
    position: absolute;
    left: @primary-spacing;
    right: @primary-spacing;
    bottom: @primary-spacing;
    font-size: @font-size-p-small-mobile;
  }
}
