@import (reference) '~/stylesheets/core.less';

.packageGrid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;

  @media (min-width: @screen-sm-min) {
    margin-top: 24px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
