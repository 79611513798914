@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@award-image-size: 80px;

// This isn't exported from zola-ui :(
@tooltip-container-width: 246px;
@tooltip-arrow-size: 9px;

@first-tooltip-mobile-adjustment: 80px;

.awards {
  margin-top: @spacing-md;

  .awardsList {
    display: flex;
    flex-wrap: wrap;
    gap: @spacing-16;
  }

  .award {
    position: relative;

    img {
      height: @award-image-size;
      width: @award-image-size;
      border-radius: 50%;
    }

    .toolTip {
      position: absolute;
      width: @tooltip-container-width;

      // How large is the image + the arrow size + how much we want the arrow above the circle
      bottom: @award-image-size + @tooltip-arrow-size + @spacing-sm;

      // Move the image over half the difference in the widths of the image and tooltip
      left: -1 * (@tooltip-container-width - @award-image-size) / 2;

      :global {
        .tooltip {
          font-size: @zui-fontsize-small;
          padding: @spacing-sm @spacing-xs;
        }
      }
    }

    // Move first tooltip to right on mobile so that it doesn't get cut off
    @media (max-width: @screen-xs-max) {
      &:first-child .toolTip {
        left: -1 * (@tooltip-container-width - @award-image-size) / 2 +
          @first-tooltip-mobile-adjustment;
        :global {
          .tooltip::before {
            left: -2 * @first-tooltip-mobile-adjustment;
          }
        }
      }
    }
  }
}
