// base typography styles that can be imported into any css modules file
// variables come from _variables.less in the zola-ui repo

.heavy {
  font-weight: @proxima-semi-bold;
}

.base {
  font-family: @font-family-base;
  // TODO: this causes issues as a mixin because overrides
  font-weight: @proxima-regular;
}

// 12px
.extra-small {
  .base();

  font-size: @zui-fontsize-extra-small;
  letter-spacing: 0;
}

// 12px bold
.extra-small-heavy {
  .extra-small();
  .heavy();
}

// 14 px
.smaller {
  .base();

  font-size: @zui-fontsize-smaller;
  line-height: @zui-fontsize-regular;
}

// 14px bold
.smaller-heavy {
  .smaller();
  .heavy();
}

// 16px
.small {
  .base();

  font-size: @zui-fontsize-small;
  line-height: @zui-fontsize-large;
}

// 16px bold
.small-heavy {
  .small();
  .heavy();
}

// 20px
.regular {
  .base();

  font-size: @zui-fontsize-regular;
  line-height: @zui-fontsize-large;
}

// 20px bold
.regular-heavy-no-responsive {
  .heavy();

  font-size: @zui-fontsize-regular;
  line-height: @zui-fontsize-larger;
}

// 20px bold, but on small screens, 16px
.regular-heavy {
  .regular-heavy-no-responsive();

  @media (max-width: @screen-xs-max) {
    font-size: @zui-fontsize-small;
    line-height: @zui-fontsize-regular;
  }
}

// 24px
.large {
  .base();

  font-size: @zui-fontsize-large;

  // Design system uses a 24px font + 36px line height for h2
  line-height: 36px;
}

.large-compact {
  // For some h2 placements, the design system uses a 24px font with a
  // 28px line spacing when the taller 36px line spacing makes the text/page look
  // stretched out.
  line-height: 28px;
}

// 24px bold
.large-heavy {
  .large();
  .heavy();
}

// huge is typically used for serif h1 elements
// 56px except on mobile, where it goes to 32px
.huge {
  font-family: @font-family-serif;
  font-size: @zui-fontsize-huge;
  font-weight: @font-weight-medium;
  line-height: 68px;

  @media (max-width: @screen-xs-max) {
    font-size: @zui-fontsize-larger;
    line-height: 36px;
  }
}

.serif {
  font-family: @font-family-serif;
  font-weight: @font-weight-medium;
}

.spirit {
  font-family: @font-family-new-spirit;
  font-weight: @font-weight-medium;
}

.serif-heavy {
  .serif();
}

// helpers
.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.flex {
  display: flex;
}

.full-width {
  width: 100%;
}
