@import (reference) '~/stylesheets/core.less';

.showcasesSection {
  hr {
    margin: 0;
    color: @color-outline-gray;
  }

  .heading {
    margin-bottom: 24px !important;
  }

  :global {
    .tab-navigation {
      white-space: nowrap;
      overflow-x: auto;

      // hide scrollbar
      // design is considering adding some other scroll affordance
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: -ms-autohiding-scrollbar; // IE, Edge
      scrollbar-width: none; // Firefox
    }
  }
}
