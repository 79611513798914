@import (reference) '~/stylesheets/core.less';

.unclaimedModule {
  display: flex;
  gap: @spacing-8;
  margin-bottom: @spacing-24;
  padding: @spacing-16;
  border-radius: @spacing-16;
  border: 1px solid @color-black-030;

  // This is necessary because the favorite button has some unexplainable gap that pushes the height up
  & > div {
    height: 44px;

    .mobile({
      height: 52px; // Legacy sizing, but the favorite button needs to be fixed before this can change
    });
  }

  .learnMoreButton {
    flex-grow: 1;

    .mobile({
      height: 52px; // Legacy sizing, but the favorite button needs to be fixed before this can change
    });
  }
}
