/** Maintains a 16x9 ratio for the element */
.video-thumbnail-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  .video-thumbnail-content {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
