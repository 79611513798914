@import (reference) '~/stylesheets/core.less';
@import (reference) '../vendorCard.module.less';

@dot-size: 10px;

.details {
  display: flex;
  flex-direction: column;
  gap: @spacing-xs;
}

.facets {
  .detailSubheading();

  line-height: 18px;
  font-weight: @font-weight-regular;
  margin-top: 0;
  .mobile({
    margin-bottom: 0;
    line-height: @zui-fontsize-regular;
  });
}

.location {
  .detailSubheading();

  font-weight: @font-weight-regular;
  margin-bottom: @spacing-xxs;
}

.pricing {
  .detailSubheading();

  white-space: nowrap;
  font-weight: normal;
  margin-top: 0;
}

.dot {
  margin: 0 6px;
  font-size: @dot-size;
  vertical-align: top;
}

.detailsItem {
  .detailSubheading();

  font-weight: normal;
  margin-bottom: @spacing-xxs;
}
