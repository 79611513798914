@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.marketplace__vendor-intro-section {
  .vendor-intro-section__avatar {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
  .vendor-intro-section__vendor-details {
    text-align: center;
  }
  .vendor-intro-section__container {
    h6 {
      color: @color-secondary-gray;
      text-transform: uppercase;
    }
    .vendor-intro-section__block-container {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .services-section__service-block {
        flex-grow: 1;
        margin-top: @primary-spacing;
      }
    }
    .vendor-intro-section__title {
      color: @color-secondary-gray;
    }
    .vendor-intro-section__fast-facts-ctn {
      font-size: @zui-fontsize-large;
      span {
        font-size: @zui-fontsize-smaller;
      }
      .three-words {
        font-weight: @font-weight-semi-bold;
        margin-right: @spacing-xxs;
      }
    }
    .vendor-intro-section__footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  strong {
    font-weight: @font-weight-bold;
  }
}

.services-section__list {
  .list-mixin();
}

.markets__block-container {
  display: flex;
  flex-direction: column;
}
