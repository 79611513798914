.desktopServices {
  :global {
    .drawer.fade-bottom {
      .drawer__button {
        position: relative;
        bottom: 80px;
      }
    }
  }
}
