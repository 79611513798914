// forces an element to a specific aspect ratio
.force-aspect-ratio(@ratio) {
  height: 0;
  /* stylelint-disable-next-line function-no-unknown */
  padding-bottom: percentage(@ratio); // percentage is a valid function with LESS
}

.full-bg-img(@img-path) {
  background: url(@img-path) no-repeat center center fixed;
  background-size: cover;
}

// logo/icon mixins
.logo(@width, @height, @svgPath, @noSvgPath) {
  .logo-size(@width, @height);

  background-image: url(@svgPath);
  background-repeat: no-repeat;
  .modernizr-no-svg & {
    background-image: url(@noSvgPath);
  }
}

// handles sizing for a logo
// mostly internal to the .logo mixin but can be used independently
.logo-size(@width, @height) {
  background-size: @width @height;
  width: @width;
  height: @height;
}

.darken-on-hover(@darken-by) {
  position: relative;
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: @color-black;
    border-radius: inherit;
    opacity: 0;
    top: 0;
    left: 0;
    transition: opacity 0.25s ease;
  }
  &:hover {
    &::before {
      opacity: @darken-by;
    }
  }
}

.keyframes(@animation-name, @content) {
  @keyframes @animation-name {
    @content();
  }
}

.transform(@transforms) {
  transform: @transforms;
}

.animation(@animation) {
  animation: @animation;
}

/* for page elements with nearby draggable items, to prevent unwanted highlighting/selection */
.guard-against-drag {
  user-select: none;
}

.no-select {
  pointer-events: none;
  user-select: none;
}

/* for overlay elements that should cover their parent and not interact */
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .no-select();
}

/**
 * Draws a gradient overlay on top of a parent that is not interactable
 *
 * @param angle - The gradient line's angle of direction. Examples: 0deg (to the top), 180deg (to the bottom), 270deg (to left) 90deg (to right).
 * @param from - the starting color value, followed by two optional stop parameters
 * @param to - the ending color value,  followed by two optional stop parameters
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/linear-gradient
 */
.gradient-overlay(@angle, @from, @to, @zindex) {
  &::after {
    content: ' ';
    background-image: linear-gradient(@angle, @from, @to);
    z-index: @zindex;
    .overlay();
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
