@import (reference) '~/stylesheets/core.less';

@color-light-outline-gray: #ededed;
@icon-size: 40px;
@card-height: 340px;

.modal-dialog .menu-card__body {
  cursor: default;
  padding: @secondary-spacing;
  padding-bottom: @primary-spacing;
  max-width: 440px;
  min-width: 400px;
  min-height: @card-height;
}

.marketplace__menu-card {
  position: relative;
  height: @card-height;
  border: 1px solid @color-light-outline-gray;
  &::after {
    content: '';
    position: absolute;
    left: @primary-spacing;
    right: @primary-spacing;
    bottom: (@primary-spacing); // Leave room for the card footer
    height: 30px;
    background-image: linear-gradient(rgba(255, 255, 255, 0), @color-white 90%);
    pointer-events: none;
  }
  .menu-card__body {
    max-height: 100%;
    overflow: hidden;
    cursor: pointer;
  }
}
