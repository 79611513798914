@import (reference) '~/stylesheets/core.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins';

.header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.sectionsV2 {
  display: flex;
  gap: @spacing-24;
  align-items: stretch;
  padding: 0 @spacing-24;
  max-width: 1440px;
  margin: 0 auto;

  &.redesignHeaderContainer {
    z-index: @zIndex-content-top;
    position: relative;
    .mobile({
      padding: 0;
    });
  }

  .not-mobile({
    padding: 0 @spacing-lg;
  });

  .desktop({
    justify-content: space-between;
    flex-wrap: nowrap;
  });

  .not-desktop({
    flex-direction: column;
  });
}

.sections {
  .content-container();

  display: flex;
  gap: @spacing-24;
  align-items: stretch;

  .desktop({
    justify-content: space-between;
    flex-wrap: nowrap;
  });

  .not-desktop({
    flex-direction: column;
  });
}

.content {
  position: relative;
  padding-bottom: @spacing-32;

  .desktop({
    width: 67%;
  });

  &.splitView {
    .desktop({
      width: 100%;
    });
  }
}

.contentInquiryModule {
  margin-top: 24px;
}

// Danger: This is matching Placeholder in JumpMenu.styles.ts
.module {
  position: relative;
  padding-top: 20px;
  padding-bottom: @spacing-32;

  .desktop({
    width: 33%;
    max-width: 350px;
  });

  .not-desktop({
    display: none;
  });

  .stickySection {
    position: sticky;
    top: 102px; // This is the height of the sticky header + 32
  }

  &.splitView {
    .desktop({
      display: none;
    });
  }
}
