@import (reference) '~/stylesheets/core.less';

.modal {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 36px;

  @media (min-width: @screen-sm-min) {
    min-width: 568px;
    padding: @spacing-lg;
  }
  .close {
    margin-left: auto;
    height: 24px;
    color: @color-black !important;
    :global {
      .zola-ui-icon-close {
        font-size: @zui-fontsize-large;
        line-height: @zui-fontsize-large;
      }
    }
  }
  .head {
    margin-bottom: 5px;
    .heading {
      margin-bottom: @spacing-md;
    }
    .pricingSection {
      margin-bottom: @spacing-md;
    }
    .dek,
    .note,
    .timeline {
      font-size: @zui-fontsize-small;
      line-height: @zui-fontsize-large;
      margin: 0;
      color: @color-text-gray !important;
    }
    .note {
      @media (max-width: @screen-xs-max) {
        margin-bottom: @spacing-md;
      }
    }
  }
  .servicesCtn {
    @media (min-width: @screen-sm-min) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: @spacing-lg;
    }
  }
  .item {
    display: flex;
    gap: 4px;
    margin: 4px 0;
    svg {
      flex-shrink: 0;
    }
  }
  .title {
    font-weight: @font-weight-semi-bold;
    margin: @spacing-sm 0;
  }
}
