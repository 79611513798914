@import (reference) '~/stylesheets/core.less';

.packagesSection {
  .not-desktop({
    overflow-x: hidden;
  });
}

.headerWrapper {
  width: 100%;
}

.desktopInquiryButton {
  position: absolute;
  top: 0;
  right: 0;
  .not-desktop({
    display: none !important;
  });
}

.mobileOnly {
  width: 100%;
  margin-top: 16px;
  .desktop({
    display: none !important;
  });
}

.seeAllDrawer {
  position: relative;
  :global {
    .see-all-drawer__control {
      .desktop({
        margin-right: 168px;
      });
    }
  }
}
