@import (reference) '~/stylesheets/core.less';

.favoriteCompact {
  &&& {
    padding: 0 !important;
    background-color: @color-white !important;
    border-radius: 50% !important;
    border: 1px solid @color-black !important;

    .mobile({
      padding: 4px !important;
      border-radius: 30px !important;
    });
  }
}

.heartImg {
  .mobile({
    width: 44px !important;
    height: 44px !important;
  });
}
