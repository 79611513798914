@import (reference) '~/stylesheets/core.less';

.overviewSection {
  margin-bottom: @spacing-24;
  padding-top: @spacing-md;
  display: flex;
  justify-content: space-between;
  .mobile({
    max-width: 100vw;
  });

  .desktop({
    .content {
      max-width: 70%;
    }
  });

  .container {
    width: 100%;
  }

  .hed {
    font-family: @font-family-serif;
    font-weight: @font-weight-medium;
    color: @color-black;
    font-size: @zui-fontsize-larger;
    line-height: 1.1;
    margin: 0;
  }

  .hr {
    margin: @spacing-16 0;
  }

  .ctaButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .shareButtonOverrides {
      border: 1px solid @color-black;
      background-color: @color-white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
      width: 44px;
      height: 44px;
      margin-top: -4px;
    }

    .inquiryButton {
      margin-right: @spacing-md;
      min-width: 148px;
    }

    .favoriteButton {
      margin-top: 0;
      margin-right: @spacing-sm;
    }
  }

  :global {
    .overview__block {
      display: inline-block;
      margin-right: @secondary-spacing;
      .location-pin-icon {
        margin-right: @quaternary-spacing;
        padding-top: @spacing-xxs;
        transform: scale(1.2);
      }
      h5 {
        display: inline-block;
      }

      .mobile({
        flex-direction: column;
      });
    }

    .overview__location {
      font-size: @zui-fontsize-small;
      color: @color-text-light-gray;

      .vendor-market {
        margin-left: @spacing-xxs;
        .mobile({
          margin: @spacing-xxs 0 0 0;
        });
      }
      .reviews-link {
        text-transform: none;
      }
    }

    .overview__flex {
      display: flex;
      flex-flow: wrap;

      .mobile({
        flex-direction: column;
      });
    }

    .overview__markets {
      margin-left: @spacing-xs;
      // color: @color-link-jewel-blue;
      // font-weight: @font-weight-semi-bold;
    }
    .__react_component_tooltip {
      &.show {
        /** override opacity of 0.9 */
        opacity: 1;
      }
    }
  }
  .hedContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: @spacing-24;
    margin-bottom: 8px;

    .shareOrFavorite {
      display: flex;
      gap: 12px;
      justify-content: center;
      .shareButton {
        border: 1px solid @color-black;
        background-color: @color-white;
        width: 44px;
        height: 44px;
      }

      .favoriteButton {
        margin-top: 0;
      }
    }
  }
}
