@import (reference) '~/stylesheets/core.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@v2-image-size: 72px;
@v2-image-size-lg: 88px;
@v2-column-gap: 16px;
@v2-max-width: 320px;
@v2-max-width-large: @v2-max-width + (@v2-image-size-lg - @v2-image-size);

.smallVendorCard {
  display: flex;
  align-items: center;
  width: 320px;
  border-radius: @spacing-16;
  border: 1px solid @color-black-015;
  text-decoration: none;
  box-shadow: none;

  .mobile({
    width: 100%;
  });

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @spacing-16;

    .image {
      width: 64px;
      height: 64px;
      border-radius: 8px;
    }
  }

  .info {
    width: 100%;
    padding-top: @spacing-16;
    padding-right: @spacing-16;
    padding-bottom: @spacing-16;

    .category {
      color: @color-black-075;
      font-size: @zui-fontsize-smaller;
      font-weight: @font-weight-regular;
    }

    .name {
      max-height: 46px;
      color: @color-black-100;
      font-size: @zui-fontsize-small;
      font-weight: @font-weight-semi-bold;
    }

    .location {
      color: @color-black-100;
      font-size: @zui-fontsize-smaller;
      font-weight: @font-weight-regular;
    }
  }

  &.v2 {
    border: none;
    border-radius: 0;
    width: auto;
    max-width: @v2-max-width;
    text-decoration: none;
    align-items: flex-start;
    column-gap: @v2-column-gap;

    &.alignCenter {
      align-items: center;
    }

    .imageContainer {
      padding: 0;

      .image {
        width: @v2-image-size;
        height: @v2-image-size;
        border-radius: 16px;
      }
    }

    .info {
      padding: 0;
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      max-width: calc(@v2-max-width - @v2-image-size - @v2-column-gap);

      .infoRow {
        font-size: @zui-fontsize-smaller;
        line-height: 20px;
        color: @color-black-075;
        font-weight: 400;
        margin: 0;

        > * {
          color: @color-black-075;
        }
      }
    }

    &.large {
      max-width: @v2-max-width-large;

      .info {
        max-width: calc(@v2-max-width-large - @v2-image-size-lg - @v2-column-gap);
      }
      .imageContainer {
        .image {
          width: @v2-image-size-lg;
          height: @v2-image-size-lg;
          border-radius: 16px;
        }
      }
    }

    .name {
      color: @color-black-100;
      font-weight: 600;
      line-height: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      .mobile({
        text-decoration: underline;
      });
    }

    &:hover {
      box-shadow: none;

      .name {
        text-decoration: underline;
      }
    }
  }

  &:global(.clickable):hover {
    box-shadow: none !important;

    .info {
      .name {
        text-decoration: underline;
      }
    }
  }
}

.tag {
  user-select: none;
}
