@import (reference) '~/stylesheets/core.less';

@card-height: 350px;

.packageCard {
  position: relative;
  border: 1px solid @color-outline-gray;
  border-radius: 4px;
  max-width: 400px;
  height: @card-height !important;
  overflow: hidden;
  @media (max-width: @screen-xs-max) {
    padding: @spacing-sm;
  }
  &.preview {
    height: fit-content !important;
    min-height: @card-height;
  }
  .fade {
    position: absolute;
    height: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), @color-white);
    border-bottom: 30px solid white;
    pointer-events: none;
  }
  .moreButtonContainer {
    font-size: @font-size-p;
    display: flex;
    justify-content: flex-end;
    .moreButton {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 13px;
      @media (max-width: @screen-xs-max) {
        bottom: 8px;
      }
    }
  }
}
