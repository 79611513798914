@import (reference) '~/stylesheets/core.less';

@card-height: 350px;

.packageCardContent {
  height: 100%;
  cursor: pointer;
  &.preview {
    cursor: default;
  }
  ul {
    padding-left: @spacing-md;
    li {
      display: list-item;
      list-style-type: initial;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  .body {
    .name {
      font-weight: @font-weight-medium;
      margin: 0 0 10px 0;
      letter-spacing: @letter-spacing-h3;
      &.placeholderColor {
        color: @color-background-dark-gray;
      }
    }
    .semiBold {
      font-weight: @font-weight-semi-bold;
    }
    .marginTop {
      margin-top: @spacing-xxs;
    }
    .price {
      margin-right: @spacing-xxs;
    }
    .description {
      font-size: @zui-fontsize-smaller;
      margin-top: @spacing-md;
    }
    .descriptionText {
      margin: 0;
      &.truncate {
        display: -webkit-inline-box; // stylelint-disable-line
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        width: 100%;
      }
      &.placeholderColor {
        color: @color-background-dark-gray;
      }
    }
    .included {
      font-weight: @font-weight-semi-bold;
      width: 400px;
      margin-top: @spacing-md;
    }
    .includedItems {
      color: @color-emperor;
      font-size: @zui-fontsize-smaller;
    }
  }
}

:global(.modal-dialog) {
  .packageCardContent {
    cursor: default;
    padding: @secondary-spacing;
    padding-bottom: @primary-spacing;
    max-width: 440px;
    min-width: 400px;
    min-height: @card-height;
    .descriptionText {
      &.truncate {
        -webkit-line-clamp: unset;
      }
    }
  }
}
