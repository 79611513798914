@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.services-modal-container {
  min-width: 300px;
  max-width: 95vw;
  width: 700px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;

  .mobile({
    margin: @spacing-sm;
  });
}

.services-modal-header-text {
  margin-bottom: 12px !important;
  font-family: @font-family-base !important;
}

.services-modal-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: @spacing-lg;
  flex-grow: 1;
  max-height: 60vh;
  overflow-y: auto;

  .services-list {
    margin-left: @spacing-xl;

    .mobile({
      margin: 0;
    });
  }

  .mobile({
    flex-direction: column;
  });
}

.services-modal-list-title {
  font-weight: @font-weight-semi-bold;
  color: @color-black;
  line-height: 24px;
  padding-right: @spacing-md;
}

.services-modal-button-container {
  display: flex;
  justify-content: flex-end;
}

.label {
  color: @color-text-gray;
  font-size: @zui-fontsize-smaller;
}

.mobile-margin {
  .mobile({
    margin-top: @spacing-lg;
  });
}
