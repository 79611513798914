@import (reference) '~/stylesheets/core.less';

.overviewLocation {
  .reviewsLink {
    margin-top: 8px;
  }

  .yelpAverageRating {
    img {
      width: 76px;
    }
  }
  .titleLinks {
    display: flex;
    align-items: center;
    gap: @spacing-8;
    margin-top: @spacing-8;
    hr {
      border: none;
      background-color: @color-black-100;
      width: 1px;
      height: @spacing-16;
      margin: 0;
    }
  }
}
