@import (reference) '~/stylesheets/core.less';

/* https://www.designcise.com/web/tutorial/how-to-fix-issues-with-css-position-sticky-not-working#checking-if-an-ancestor-element-has-overflow-property-set */
/* stylelint-disable-next-line css-modules/no-global-scoped-selector */
.desktop({
  html {
    overflow-x: unset;
  }
});

.inquiryModule {
  position: relative;
}

.inquiry {
  border-radius: @spacing-16;
  border: 1px solid @color-black-030;
  padding: @spacing-16;

  &.squareBottomBorder {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .text {
    font-weight: @font-weight-semi-bold;
    margin-bottom: @spacing-sm;
    font-size: @zui-fontsize-small;
    text-align: start;
    color: @color-black;
  }

  .rapidText {
    font-size: @zui-fontsize-small;
  }

  .ctaButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: @spacing-16;
    gap: 12px;

    &.verticalStack {
      flex-direction: column;
      gap: 4px;
    }

    .inquiryButton {
      flex-grow: 1;
      line-height: 24px;
      height: 56px;
      font-size: @zui-fontsize-small;
    }

    .shareOrFavorite {
      display: flex;
      gap: 12px;
      justify-content: center;
    }

    .shareButton {
      border: 1px solid @color-black;
      background-color: @color-white !important;
      width: 44px;
      height: 44px;
    }

    .favoriteButton {
      margin-top: 0;
    }

    .ignoreButton {
      min-width: fit-content;
    }
  }

  .firstMovePrompt {
    font-size: @zui-fontsize-small;
    font-weight: @font-weight-regular;
  }
}

.footer {
  .inquiry {
    background-color: @color-black-005;
    border: none;
    border-radius: @spacing-16;
  }
  .text {
    text-align: center;
    font-size: @zui-fontsize-regular;
  }
  .rapidText {
    text-align: center;
  }
  .ctaButtons {
    .inquiryButton {
      max-width: 160px;
    }
  }
}

.socialProof {
  background-color: @color-mint;
  padding: @spacing-md;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: @spacing-md;
  border-radius: @spacing-sm;
  .dek {
    color: @color-forest-100;
    font-family: @font-family-sans-serif;
    font-size: @zui-fontsize-smaller;
    font-weight: @font-weight-semi-bold;
    margin-left: @spacing-10;
  }
}
