@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  position: relative;
  padding: @spacing-sm @spacing-lg;
  overflow: hidden;
  background-color: @color-white;
  width: 540px;
  .mobile({
    width: 100%;
  });

  .headerContainer {
    min-height: 170px;
    margin-bottom: @spacing-xs;
  }

  .storefrontHeader {
    min-height: 0;
  }

  .hed {
    font-weight: @proxima-semi-bold;
    font-size: @zui-fontsize-large;

    .mobile({
      font-size: @zui-fontsize-small;
    });
  }
}

/* stylelint-disable-next-line css-modules/no-global-scoped-selector */
:global .marketplace__link-sharing-modal-container {
  border-radius: 4px;
}
