@import (reference) '~/stylesheets/core.less';

@z-index-dropdown: 20;

.inputDropdown {
  position: relative;
  width: 100%;

  .storefrontPlaceholder,
  .storefrontThumbnail {
    flex-shrink: 0;
  }

  .storefrontThumbnail {
    border-radius: 50%;
    background-color: @color-black-005;
  }

  .vendorCategory,
  .locationOption,
  .vendorOption,
  .storefrontOption {
    display: flex;
    align-items: center;
    gap: 12px;

    a {
      color: @color-black-100;
      text-decoration: none;
    }
  }

  .locationOption {
    .location {
      .type {
        margin-left: 4px;
        color: @color-black-050;
        font-size: @zui-fontsize-extra-small;
      }
    }
  }

  .storefrontOption {
    gap: 8px;
    font-weight: @font-weight-regular;
  }

  .sectionTitle {
    font-weight: @font-weight-semi-bold;
    padding: 8px 12px;

    &:empty {
      padding: 0;
    }
  }

  .noMatchesContainer {
    font-size: @zui-fontsize-smaller;

    p {
      font-size: @zui-fontsize-small;
      margin-bottom: @spacing-xxs;
    }
    // remove parent padding
    margin: -@spacing-xs -@spacing-sm;
    padding: @spacing-sm @spacing-sm @spacing-sm;
    font-weight: @font-weight-regular;
    border-bottom: 0.4px solid @color-screaming-grey;
  }

  :global .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    position: absolute;
    width: calc(100% - 30px);
    margin-top: 8px; // Spacing between the input and the suggestions container
    border: 1px solid @color-black-030;
    border-radius: 4px;
    background-color: @color-white-100;
    overflow: hidden;
    pointer-events: none; // Prevent clicks on the section header, which will throw an error otherwise
    z-index: @z-index-dropdown; // Appear above other page elements like the tags on vendor cards

    .react-autosuggest__suggestion {
      padding: 8px 12px;
      color: @color-black-100;
      cursor: pointer;
      pointer-events: auto;

      &:hover,
      &.react-autosuggest__suggestion--highlighted {
        background: @color-black-005;
      }
    }
  }
}
