@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.hed {
  .large();
  .serif-heavy();

  color: @color-black;
  margin-bottom: @spacing-xxs;
}

.pricingSection {
  display: flex;
  margin-bottom: @spacing-md;
  flex-direction: column;
  .tooltip {
    margin-bottom: @spacing-md;
    max-width: calc(100vw - @spacing-xl);
  }
  :global {
    .input-field__container {
      min-height: 68px;
    }
  }
}

.priceRange {
  max-width: unset;
  display: grid;
  grid-template-columns: 1fr 1fr;
  input {
    min-width: unset;
    width: 138px;
  }
}

.lowEnd {
  margin-right: @spacing-md;
}

.section {
  max-width: 940px;
  margin: 0 auto;
  position: relative;

  p {
    margin-bottom: 11px;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  margin-bottom: @spacing-lg;
}

.icon {
  width: 58px;
  height: 58px;
  position: absolute;
  left: -75px;
}

.dek {
  .small();

  color: @color-text-gray;
}

.facets {
  margin-top: @spacing-lg;
  .section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
.requiredContainer {
  position: relative;
  .required {
    position: absolute;
    color: @color-red;
    left: 2px;
    top: -20px;
  }
}
.requiredCheck {
  display: flex;
  margin-top: @spacing-md;
}
.label {
  color: @color-black;
  .small-heavy();
}
.column {
  margin-right: @quaternary-spacing;
  margin-left: @quaternary-spacing;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.previewLink {
  display: block;
  margin-bottom: @spacing-md;
}

.pricingLabel {
  color: @color-black;
  min-width: 271px;
  margin-right: 62px;
  .small-heavy();
  :global {
    .danger-asterisk {
      margin-left: 2px;
    }
  }
}

.pricingForm {
  display: flex;
  flex-direction: column;
}

.perPersonNotRelevant {
  background-color: @color-background-light-gray;
  padding: @spacing-xs @spacing-xs @spacing-xs 16px;
  margin-top: @spacing-xs;
  ul {
    padding: 0;
  }
}

.pricingCalculator {
  background-color: #ebf4f9;
  padding: 20px;
  margin-bottom: 40px;
  .small();
  .priceCalculatorHeader {
    background-color: white;
    box-shadow: 0 2px 4px 0 #0000001a;
    border-radius: 4px;
    padding: @spacing-xxs @spacing-xs @spacing-xxs @spacing-xxs;
    margin-bottom: @spacing-xs;
    color: #21201f;
    span {
      padding: 0 @spacing-xs;
    }
    .smaller-heavy();
  }
  .price {
    color: #21201f;
    padding-top: 8px;
    .regular-heavy();
  }
  .column {
    margin-right: @quaternary-spacing;
    margin-left: @quaternary-spacing;
    margin-top: @spacing-sm;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
}
.priceDisplaySettingsLabel {
  .small-heavy();

  color: @color-black;
  padding-bottom: 16px;
}

.hide-price {
  margin-top: @spacing-md;
}
