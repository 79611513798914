@import (reference) '~/stylesheets/core.less';
@drag-border-width: 4px;

.draggable-photo-wrapper {
  .draggable-photo-drag-handle {
    &:hover {
      cursor: move;
    }
  }

  .draggable-photo-drag-handle.drag-active {
    opacity: 0.5;
    cursor: grab;
  }
  &.draggable-photo-drop-target {
    border-top: @drag-border-width solid transparent; // So nothing jumps around when we add a border
    border-bottom: @drag-border-width solid transparent; // So nothing jumps around when we add a border
  }

  &.draggable-photo-drop-target.drop-active {
    border: @drag-border-width solid @color-primary-teal;
  }
}
