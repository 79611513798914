/* stylelint-disable no-descending-specificity */

@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

@icon-size: 70px;
@ratio: 0.67;
@sevenToFive: 5/7;

.detailSubheading() {
  font-size: @zui-fontsize-smaller;
  line-height: 100%;
}

.recommendation {
  .detailSubheading();
}

.ratingsContainer {
  display: flex;
  gap: @spacing-8;
  margin-top: @spacing-8;

  .ratingsWrapper {
    font-size: @zui-fontsize-smaller;
    font-weight: @font-weight-regular;

    // This is necessary to override the annoyingly fixed styling in the StarRatings component
    svg + div {
      font-size: @zui-fontsize-smaller;
    }
  }
}

.card-container {
  height: 100%;
  margin-bottom: @spacing-md;

  &.noMargin {
    margin-bottom: 0;
  }

  .vendor-card {
    color: @color-black;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    .body {
      padding-top: @spacing-sm;
      padding-bottom: @spacing-md;
      display: flex;
      flex-direction: column;
      gap: @spacing-xxs;
      flex-grow: 1;

      .tablet({
        padding-bottom: 0;
      });
    }

    .bodyWithAward {
      padding-bottom: 0;
    }

    .vendor-name {
      font-size: @zui-fontsize-small;
      line-height: 24px;
      font-weight: @proxima-semi-bold;
      margin-bottom: @spacing-xxs;
    }

    .availability {
      margin-top: @spacing-sm;
      font-weight: @proxima-regular;
    }
    .availabilityBadge {
      .detailSubheading();

      background-color: @color-black-005;
      border-radius: @border-radius;
      padding: @spacing-xxs @spacing-xs;
      .availabilityDate {
        font-weight: @proxima-semi-bold;
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: @color-black;
      text-decoration: none;
    }

    :global {
      .tooltip__container {
        position: absolute;
        top: -40px;
        opacity: 0;
        z-index: @zIndex-content-middle - 1;
        pointer-events: none;
      }
    }

    &:hover {
      .vendor-name {
        text-decoration: underline;
      }
    }

    &.readOnly {
      &:hover {
        .vendor-name {
          text-decoration: none;
        }
      }
    }
  }

  .favorite-button {
    position: absolute;
    top: 0;
    right: 0;

    .mobile({
      right: -@spacing-sm;
    });

    button {
      padding: 0;
    }
  }

  .quickResponderButton {
    top: @spacing-xs;
    left: @spacing-xs;
    &:hover,
    &:focus {
      + :global {
        &.tooltip__container {
          opacity: 1;
        }
      }
    }
  }

  .vendorCardDetailsContainer {
    display: grid;
    gap: @spacing-xs;
    grid-template-columns: auto 56px;
    height: 100%;
  }

  .vendorCardDetails {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: @spacing-xs;
  }

  .starRatingsLocation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: @spacing-xs;
  }

  .location {
    .detailSubheading();

    font-weight: @font-weight-regular;
  }

  .bestOfZolaAward {
    display: flex;
    align-items: center;
  }
}
