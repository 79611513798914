@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

hr.divider {
  margin: 0;
  color: @color-outline-gray;
}

.eventSpaces {
  &.desktop {
    .not-desktop({
      display: none;
    });
  }
  &.mobile {
    margin-top: 24px;

    .desktop({
      display: none;      
    });

    .spaceName {
      display: none;
    }
  }
}

.eventSpace {
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 24px;
  border-bottom: 1px solid @color-black-030;

  .desktop({
    padding: 40px 0;
  });

  .not-desktop({
    display: flex;
    flex-direction: column-reverse;
    border-bottom: none;
  });

  &:last-of-type {
    border-bottom: none;
  }
}

.eventSpacePhoto {
  cursor: pointer;
}

/* stylelint-disable-next-line no-descending-specificity */
.spaceName {
  margin-bottom: 8px;
}

.spaceBody,
.spaceDescription {
  display: flex;
  flex-direction: column;
}

.spaceDescription {
  gap: 8px;
}

.spaceBody {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  gap: 16px; // BODY_GAP in the component
  transition: all 0.5s ease;
}

.spaceDetailsPair {
  display: flex;
  width: 100%;
  gap: 40px;
  align-items: flex-start;

  .not-desktop({
    flex-direction: column;
    gap: 16px;
  });
}

.included {
  white-space: pre-line;
}

.label {
  &&& {
    font-size: 14px;
  }
}

.floorPlanLink {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 20px;
  font-size: 14px;
}
