@import (reference) '~/stylesheets/core.less';

.pricing {
  .section {
    margin-bottom: @spacing-md;
    .title {
      margin-bottom: @spacing-sm;
      .icon {
        margin-right: @spacing-xs;
        svg {
          position: relative;
          top: @spacing-4;
        }
      }
    }

    .link {
      margin-top: @spacing-sm;
    }
  }
}
