@import (reference) '~/stylesheets/core.less';
@import './videoThumbnail.less';

form#marketplace-vendors-storefront-edit-video {
  text-align: left;
  padding-bottom: @primary-spacing;

  .text-danger,
  .danger-asterisk {
    color: #c7133e;
  }

  .row {
    margin-left: auto;
    margin-right: auto;
    @media (min-width: @screen-lg-min) {
      display: flex;
      justify-content: center;
    }
  }
  .video-form {
    padding-left: @primary-spacing;
    @media (max-width: @screen-xs-max) {
      @padding-left: @tertiary-spacing;
      @padding-right: @tertiary-spacing;
    }
  }
  .video-thumbnail {
    margin-bottom: @primary-spacing;
  }

  .video-host {
    margin-bottom: @secondary-spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-label {
      flex-grow: 1;
      font-weight: @font-weight-bold;
    }
    .render-radio-field {
      margin-right: 0;
      flex-grow: 1;
      text-align: center;
      &:last-child {
        text-align: right;
      }
    }
  }
  .host-id {
    margin-bottom: @secondary-spacing;
    display: flex;
    justify-content: center;
    align-items: center;

    &-label {
      font-weight: @font-weight-bold;
      margin-right: @tertiary-spacing;
    }
    .render-field {
      flex-grow: 1;
    }
  }
  .video-type,
  .video-title {
    margin-bottom: @secondary-spacing;
    &-label {
      text-align: left;
      font-weight: @font-weight-bold;
      text-transform: uppercase;
      color: @color-black;
      font-size: @font-size-p-small;
      letter-spacing: 1.2px;
    }
  }

  .single-image-upload__icon {
    width: 50px;
  }
}
