@import (reference) '~/stylesheets/core.less';

.buttonBlock {
  display: flex;
  position: sticky;
  align-items: center;
  left: 0;
  bottom: 0;
  background-color: @color-alabaster;
  justify-content: space-between;
  gap: 16px;
  border-top: 1px solid @color-background-light-gray;
  padding: 20px;
  width: 100%;
}

.saveAndCancel {
  display: flex;
  gap: 8px;

  :global(button) {
    min-width: 90px;
  }
}

.deleteButton {
  border: none;
  padding: 0 16px;
  margin: 0 -16px;
  line-height: 1;
  gap: 4px;
  background-color: transparent !important;
  opacity: 1;
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }
}
