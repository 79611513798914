/* stylelint-disable css-modules/no-global-scoped-selector */
@import (reference) '~/stylesheets/core.less';
@import '../../../../../components/common/zolaUI/renderDatePicker/renderDatePicker.less';

.dateRangePicker {
  &&& {
    margin-bottom: @spacing-md;
    label {
      text-transform: none;
      font-size: @zui-fontsize-small;
    }
    input {
      border-width: 2px;
      border-radius: @spacing-xxs;
      &:focus {
        border-color: @color-link-jewel-blue;
      }
    }
  }

  :global {
    .InputFromTo-to .DayPickerInput-Overlay {
      left: -198px;
      .tablet({
        left: -245px;
      });
    }
    .from-field {
      margin-right: @spacing-lg;
      .InputFromTo .DayPickerInput-Overlay {
        .tablet({
          left: -68px;
        });
      }
    }
  }

  display: flex;
}
.months {
  flex-wrap: nowrap;
}

.captionLabel {
  font-size: @zui-fontsize-small;
}

.day {
  height: 30px;
  &--highlighted:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: @color-background-jewel-8;
  }

  border-radius: 0;
  &--start,
  &--end {
    color: @color-white;
    background-color: @color-navy !important;
  }
  &--start {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  &--end {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    &:hover {
      color: @color-white;
      background-color: @color-navy !important;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  }
}
