@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/animations/_mixins.less';
@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.tagContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  box-shadow: @default-box-shadow;
  z-index: @zIndex-content-middle - 1;
  padding: 0 @spacing-xs;
  position: absolute;
  top: @spacing-xs;
  left: @spacing-xs;
}

.tagText {
  width: max-content;
  font-family: @font-family-sans-serif;
  font-weight: 400;
  font-size: @zui-fontsize-extra-small;
  line-height: 22px;
}

.icon {
  margin-right: @spacing-xxs;
  width: 14px;
  height: 14px;
}
