@import (reference) '~/stylesheets/core.less';

.unclaimedSimilarSection {
  margin-bottom: @spacing-32;

  .hr {
    margin-top: @spacing-lg;
    margin-bottom: @spacing-lg;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: @spacing-8;
    margin-bottom: @spacing-32;

    h2 {
      .location {
        white-space: nowrap;
      }
    }

    a {
      font-size: @zui-fontsize-small;
      line-height: 32px;
      white-space: nowrap;

      .mobile({
        line-height: 28px;
      });
    }
  }

  .searchResults {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: @spacing-32;

    .mobile({
      overflow: scroll;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    });

    .searchCard {
      .mobile({
        min-width: 75vw;
      });
    }
  }

  &.modal {
    margin-bottom: 0;

    .hr {
      display: none;
    }

    .heading {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: @spacing-16;
    }

    .searchResults {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: @spacing-16;

      .mobile({
        grid-template-columns: repeat(1, 1fr);
      });
    }
  }
}
