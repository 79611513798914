@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.marketplace__venue-intro-section {
  .metadata__list {
    line-height: 25px;
    list-style: none;
    padding: 0;
    margin-top: 0;
  }

  .venue-intro-section__container {
    display: flex;
    justify-content: space-between;
    @media (max-width: @screen-xs-max) {
      flex-direction: column;
    }
    .venue-intro-section__heading {
      margin-bottom: 30px;
    }
    .venue-intro-section__about {
      flex: 3;
      @media (max-width: @screen-xs-max) {
        flex-shrink: 0;
      }
      .venue-intro-section__contact {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0;
      }
    }
    .venue-intro-section__metadata {
      flex: 1;
      padding-left: 90px;
      @media (max-width: @screen-xs-max) {
        margin-top: @primary-spacing;
        padding-left: 0;
      }
      .metadata__heading {
        font-size: @zui-fontsize-smaller;
      }
    }
    .venue-intro-section__location {
      flex: 2;
      padding-left: 50px;
      @media (max-width: @screen-xs-max) {
        margin-top: @primary-spacing;
        padding-left: 0;
      }

      .venue-intro-section__map {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-color: @color-secondary-background-gray;
        img {
          width: 100%;
          height: 100%;
        }
        .venue-intro-section__map-link {
          display: block;
        }
      }
    }

    .venue-intro-section__social-icons {
      margin-top: @spacing-md;
    }
  }
}
