@import (reference) '~/stylesheets/core.less';

.marketplace__card-base {
  background-color: @color-white;
  color: @color-primary-gray;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: block;
  &:focus {
    text-decoration: none;
  }
  &.clickable:hover {
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: @color-primary-gray;
    cursor: pointer;
  }
}
