@import (reference) '~/stylesheets/core.less';
@import (reference) '../vendorCard.module.less';

.vendorType {
  .detailSubheading();

  // Match the rating stars height
  line-height: 22px;

  .mobile({
    margin-bottom: @spacing-xs;
  });

  font-weight: @proxima-regular;
  margin-bottom: @spacing-xxs;
  display: grid;
  gap: 2px;
  flex-shrink: 0;
  grid-template-columns: [location] 1fr;

  .typeLocation {
    grid-area: location;
    display: flex;
    flex-wrap: wrap;
    color: @color-black-050;
  }
}

.dot {
  margin: 0 6px;
  font-size: 10px;
  vertical-align: top;
}
