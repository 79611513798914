@import (reference) '~/stylesheets/core.less';
@import '~/stylesheets/util/_type.module.less';

@font-size-inherit: inherit;

.firstMoveModule {
  display: flex;
  gap: @spacing-16;
  padding: @spacing-16;
  border: 1px solid @color-black-050;
  border-radius: @spacing-16;
  font-size: @zui-fontsize-smaller;
  line-height: 20px;
  margin-top: @spacing-32;

  &.isLdpRedesign {
    margin-top: 0;

    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
      margin-top: @spacing-32;
    }
  }

  .image {
    flex-grow: 0;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .content {
    flex-grow: 1;
  }
}
