@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';

.header {
  display: flex;
  align-items: flex-start;
}

.content {
  margin-top: 20px;
}
