@import (reference) '~/stylesheets/core.less';

.active-drag-area__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(232, 244, 246, 0.95);
  color: @color-primary-teal;
  .overlay__cta {
    font-weight: @font-weight-bold;
  }
}
