@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.heading {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleAndSubheading {
  margin-bottom: 30px;
}

h3.title {
  margin-right: 16px;
  font-size: @zui-fontsize-regular;
  font-weight: @font-weight-semi-bold;
  line-height: 32px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: @color-text-black;
  letter-spacing: @letter-spacing-h3;
}

.subHeading {
  font-weight: @font-weight-regular;
  font-size: @zui-fontsize-small;
  line-height: 24px;
}

.why {
  line-height: 32px;
  font-weight: @font-weight-semi-bold;
  font-size: @zui-fontsize-small;
  color: @color-blue;
  text-transform: initial;
}

.required {
  font-weight: @font-weight-regular;
  font-size: @zui-fontsize-extra-small;
  line-height: 16px;
  color: @color-boulder;
  align-self: flex-end;
  span {
    color: @color-shiraz-red;
  }
}

.customModalContainer {
  padding: @spacing-lg;
  display: flex;
  flex-direction: column;
  max-width: 568px;
  button {
    margin-top: @spacing-lg;
    align-self: flex-end;
  }
  h3 {
    padding-bottom: @spacing-sm;
  }
  img {
    margin-bottom: 32px;
  }
}
