@import (reference) '~/stylesheets/core.less';

@spacing-64: 64px;
@spacing-48: 48px;

.unclaimedClaimListingModal {
  padding: @spacing-64 @spacing-48 @spacing-48;

  .mobile({
    max-height: 100vh;
    overflow: scroll;
    padding: @spacing-64 @spacing-24 @spacing-24;
  });
}
