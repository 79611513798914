@import (reference) '~/stylesheets/core.less';

@venue-avatar-width: 30px;
@image-spacing: 8px;

.showcase-stories-section__container {
  font-size: @font-size-base;
  display: flex;
  gap: 64px;
  .mobile({
    flex-direction: column;
    gap: 16px;
  });
  .left {
    width: 100%;
    .venue-information {
      display: flex;
      align-items: center;
      margin-bottom: @primary-spacing;
      .venue-avatar {
        width: @venue-avatar-width;
        height: @venue-avatar-width;
        margin-right: @tertiary-spacing;
      }
      .venue-name {
        color: @color-blue;
      }
      a {
        margin-left: @quaternary-spacing;
      }
    }
    .dek {
      font-family: @font-family-serif;
      font-weight: @font-weight-medium;
      font-size: @font-size-h4;
      word-break: break-word;
    }
    .figures {
      display: flex;
      .num-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
    .content-section {
      margin-top: 32px;
      .title {
        margin-top: 8px;
      }
      ul {
        padding-left: 1.1em;
        list-style: disc outside none;
      }
    }
  }
  .right {
    width: 100%;
    .images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      figure {
        position: relative;
        min-height: 100px;
        background-color: @color-cloud-100;
        &.top {
          grid-column: 1 / span 2;
        }
        &.left {
          grid-column: 1;
          grid-row: 2 / span 2;
        }
        &.rightTop {
          grid-column: 2;
          grid-row: 2;
        }
        &.rightBotton {
          grid-column: 2;
          grid-row: 3;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        figcaption {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 16px 8px 8px;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
          color: @color-white-100;
          font-size: @zui-fontsize-smaller;
        }
      }
    }
  }
}
