@import (reference) '~/stylesheets/core.less';

.listing__example {
  &-image {
    width: 100%;
  }
  &-text-container {
    color: @color-accent-pink;
    font-size: 34px;
    font-family: @font-family-script;
    margin-bottom: @tertiary-spacing;
    text-align: left;
  }
  &-text {
    display: inline-block;
    position: relative;
    padding-left: 50px;
  }
  &-arrow {
    position: absolute;
    bottom: 2px;
    width: 40px;
    transform: scaleX(-1);
    left: 0;
  }
}
.listing__example-large {
  max-width: 600px;
  margin: 40px auto 0;
  .listing__example-text-container {
    padding-left: 60px;
  }
}

.listing__example-small {
  max-width: 350px;
  position: absolute;
  top: -35px;
  right: -50px;
  @media (max-width: @screen-xs-max) {
    right: 0;
    max-width: 300px;
    top: -15px;
  }
  @media (max-width: 630px) {
    display: none;
  }
  .listing__example-text-container {
    text-align: center;
  }
}
