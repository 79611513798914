@import (reference) '~/stylesheets/core.less';

.matrixContainer {
  margin: 0 auto;
  padding-bottom: 16px;
  width: 100%;
  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: @spacing-lg;

    .legendContainer {
      display: flex;
      .legend {
        display: flex;
        align-items: center;
        .icon {
          color: @color-background-dark-gray;
        }
        svg {
          margin-right: @spacing-xs;
        }
        span {
          color: @color-boulder;
          font-size: @zui-fontsize-small;
        }
      }
      .extraCostLegend {
        margin-left: 24px;
      }
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  th,
  td {
    border: 1px solid @color-background-light-gray; // #eee
    text-align: center;
    padding: 10px;
    width: 121px;
    height: 44px;
    vertical-align: top;
  }

  th[scope='col'] {
    border: none;
    font-size: @zui-fontsize-regular;
    line-height: @zui-fontsize-large;
    vertical-align: baseline;
  }

  td {
    font-size: @zui-fontsize-small;
    line-height: @zui-fontsize-regular;
    position: relative;
    vertical-align: middle;
    .tooltip {
      position: absolute;
      background-color: @color-emperor !important;
      border-radius: 4px;
      left: 50%;
      top: -34%;
      transform: translate(-50%, -50%);
    }
    .plusIcon {
      color: @color-background-dark-gray;
    }
  }
  .descriptionCtn {
    height: 158px;
    vertical-align: top;
    .description {
      line-height: 18px !important;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }
  }
  .descriptor {
    text-align: left;
  }

  .moreLink {
    display: block;
    margin-top: 10px;
  }

  .actions {
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }

  .footer {
    text-align: left;
    color: @color-black;
    font-size: @zui-fontsize-small;
    line-height: @zui-fontsize-regular;
  }
}
