@import (reference) '~/stylesheets/core.less';

@spacing-56: 56px;
@suggestions-container-z-index: 501; // Needs to be higher than the z-index of the sticky bar

.inlineButtonWrapper {
  .button {
    height: 100%;
  }

  .mobile({
    .button {
      height: 52px;
    }
  });

  .textCta {
    display: none;
    @media (min-width: 1280px) {
      display: block;
    }
  }
  .imageCta {
    display: block;
    @media (min-width: 1280px) {
      display: none;
    }
  }

  &.withSearchIcon {
    @media (max-width: 1279px) {
      button {
        width: 44px;
        padding: 0 !important;
      }
    }
  }
}

.desktopOnly {
  .not-desktop({
    display: none !important;
  });
}

.excludeDesktop {
  .desktop({
    display: none !important;
  });
}

.containerNormal {
  display: inline-block;
  text-align: left;

  @media (max-width: @screen-sm-max) {
    width: 75%;
  }

  @media (max-width: @screen-xs-max) {
    width: 100%;
  }

  // city/state dropdown/autosuggest styles
  .searchBar {
    display: flex;
    gap: 20px;

    @media (max-width: @screen-sm-max) {
      display: block;
      width: 100%;
    }

    .dropdownWrapper {
      @media (max-width: @screen-sm-max) {
        width: 100%;
      }

      &.searchResultsPage {
        .desktop({
          width: 310px;
        });

        @media (max-width: 1200px) {
          width: 260px;
        }
        @media (max-width: 1024px) {
          width: 220px;
        }

        :global
          .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
          z-index: @suggestions-container-z-index;
        }
      }
    }

    .buttonWrapper {
      width: 125px;

      @media (max-width: @screen-sm-max) {
        width: 100%;
      }

      .button {
        width: 133px;

        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
      }
    }
  }
}

// makes autocomplete responsive and locked to size of input
.inlineContainerNormal {
  .dropdownWrapper.inlineDropdownWrapper {
    flex: 1;
    margin-right: 0;
  }

  .inlineDropdown {
    div {
      position: relative;
      background-color: @color-transparent;

      .mobile({
        top: unset;
      });
    }

    & > div {
      padding: 0 4px;

      .mobile({
        padding: 0;
      });

      & > div {
        margin-top: 0;

        .mobile({
          background-color: @color-black-005;
          border: 2px solid @color-black-005;
          border-radius: 26px;
        });
      }
    }
  }

  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 12px;
  margin: 0;
  padding: @spacing-24 @spacing-56;
  background-color: @color-white;
  height: 100%;
  width: 100%;
  max-width: 1200px;

  .tablet({
    flex-grow: 1;
  });

  .mobile({
    border-radius: @spacing-sm;
    flex-direction: column;
    padding: @spacing-md;
  });

  .inlineSearchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    height: 56px;
    border-radius: 28px;

    &.short {
      height: 44px;
      border-radius: 22px;

      input {
        font-size: 14px;
      }
    }

    &.gray {
      background-color: @color-black-005;
    }
    &.white {
      .not-mobile({
        background-color: @color-transparent;
        border: 1px solid @color-black-030;
      });
    }

    &.reverse {
      .not-mobile({
        flex-direction: row-reverse;
      });

      gap: 4px;
    }

    .mobile({
      flex-direction: column;
      gap: 12px;
      background-color: @color-transparent;
      border: initial;
      border-radius: initial;
    });

    .divider {
      border-left: 1px solid @color-black-030;
      height: 28px;

      .mobile({
        display: none;
      });
    }
  }
}

.modalHeading {
  font-size: @zui-fontsize-regular;
  color: @color-black;
}
