@import (reference) '~/stylesheets/core.less';

.marketplace__wedding-scanner-section {
  height: 440px;
  margin-bottom: @spacing-lg;
  margin-top: @spacing-md;
  @media (max-width: @screen-sm-max) {
    height: auto;
  }
  .section-hr {
    margin-top: @spacing-lg;
    margin-bottom: @spacing-lg;
  }
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
  .wedding-scanner-section__flex-container {
    display: flex;
    height: 100%;
    padding-top: @spacing-md;
    @media (max-width: @screen-sm-max) {
      flex-direction: column;
    }
  }
  .wedding-scanner-section__item-container {
    flex: 1 1 auto;
    padding-right: 20px;
    @media (max-width: @screen-sm-max) {
      height: calc(~'100vw * 0.5625');
      padding-right: 0;
      padding-bottom: 20px;
    }
    &:first-child {
      flex-grow: 1.5;
    }
    &:last-child {
      padding-right: 0;
      @media (max-width: @screen-sm-max) {
        padding-bottom: 0;
      }
    }
  }
}
