@import (reference) '~/stylesheets/core.less';

.unclaimedClaimListingForm {
  width: 500px;

  .mobile({
    width: 100%;
  });

  .heading {
    margin-bottom: @spacing-8;
  }

  .subheading {
    margin-bottom: @spacing-32;
    color: @color-black-075;

    .mobile({
      margin-bottom: @spacing-24;
    });
  }

  .form {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: @spacing-16;

    .mobile({
      grid-template-columns: 1fr;
    });

    .requestType {
      grid-column: 1 / span 6;

      .mobile({
        grid-column: 1;
      });
    }

    .firstName {
      grid-column: 1 / span 3;

      .mobile({
        grid-column: 1;
      });
    }

    .lastName {
      grid-column: 4 / span 3;

      .mobile({
        grid-column: 1;
      });
    }

    .emailAddress {
      grid-column: 1 / span 3;

      .mobile({
        grid-column: 1;
      });
    }

    .phoneNumber {
      grid-column: 4 / span 3;

      .mobile({
        grid-column: 1;
      });
    }

    .businessName {
      grid-column: 1 / span 6;

      .mobile({
        grid-column: 1;
      });
    }

    .streetAddress {
      grid-column: 1 / span 6;

      .mobile({
        grid-column: 1;
      });
    }

    .city {
      grid-column: 1 / span 2;

      .mobile({
        grid-column: 1;
      });
    }

    .stateCode {
      grid-column: 3 / span 2;

      .mobile({
        grid-column: 1;
      });
    }

    .zipCode {
      grid-column: 5 / span 2;

      .mobile({
        grid-column: 1;
      });
    }

    .submit {
      grid-column: 1 / span 6;

      .mobile({
        grid-column: 1;
      });

      .info {
        display: flex;
        justify-content: space-between;
        gap: @spacing-16;
        margin-bottom: @spacing-32;
        color: @color-black-075;
        font-size: @zui-fontsize-extra-small;

        .error {
          color: @color-semantic-negative-100;
        }

        .required {
          white-space: nowrap;
        }
      }

      .terms {
        margin-top: @spacing-16;
        color: @color-black-075;
        text-align: center;
      }
    }
  }
}
