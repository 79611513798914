@import (reference) '~/stylesheets/core.less';

.container {
  width: 100%;

  &::before {
    content: '';
    display: table;
    box-sizing: border-box;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
    box-sizing: border-box;
  }
}

.link {
  font-size: @zui-fontsize-small !important;
  line-height: 1.1;
}

.mainContent {
  text-align: center;
  @media (min-width: @screen-sm-min) {
    text-align: left;
  }
}

.hed {
  color: @color-black;
  margin: 0;
  font-size: @zui-fontsize-huge;
  font-family: @font-family-new-spirit;
  line-height: 1.1;
  font-weight: @font-weight-medium;

  .tablet({
    font-size: @zui-fontsize-larger;
  });

  .mobile({
    font-size: @zui-fontsize-larger;
  });
}

.headerContainer {
  margin-top: 65px;
}

.dekContainer {
  margin-top: 50px;
  @media (min-width: @screen-sm-min) {
    max-width: 50%;
  }
}

.similarVendorLinkContainer {
  padding-top: 40px;
  padding-bottom: 25px;
}

.unpublishedImage {
  display: none;
  @media (min-width: @screen-sm-min) {
    display: block;
    float: right;
    max-width: 50%;
  }
}
