@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

@ratio: 0.67;
@icon-size: 70px;
@slick-button-z-index: 1;

.cardBody {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  position: relative;
  height: 0;
  transition: all 0.3s ease-in-out;

  .force-aspect-ratio(@ratio);
  &.aspectRatio4_3 {
    .force-aspect-ratio(0.75);
  }

  &:hover {
    .desktop({
      &.scaleOnHover {
        transform: scale(1.04);
      }

      &.altScaleOnHover img {
        transform: scale(1.1);
      }
    });
  }

  :global {
    .modern-slick-dots();

    .carousel__container {
      .desktop({
        &:hover,
        &:active,
        &:focus {
          .arrow-button {
            visibility: visible;
            display: inline-block;

            &:focus {
              outline: none;
            }
          }
        }
      });

      .slick-slide {
        & > div {
          width: 100%;
        }
      }

      .arrow-button {
        visibility: hidden;
        display: none;

        &.slick-prev {
          left: @spacing-sm;
          z-index: @slick-button-z-index;
        }

        &.slick-next {
          right: @spacing-sm;
        }
      }

      .slick-dots {
        position: absolute;
        bottom: 10px;
        left: 50%;
        right: 50%;

        .carousel-dot {
          background-color: @color-white;
          opacity: @ratio;
          height: @spacing-xs;
          width: @spacing-xs;

          &:hover {
            background-color: @color-white;
            opacity: 1;
          }
        }

        .slick-active .carousel-dot {
          opacity: 1;
          height: @spacing-xs;
          width: @spacing-xs;
          background-color: @color-white;
        }
      }
    }
  }
}

.carouselPhoto {
  overflow: hidden;
  height: 0;
  border-radius: 16px;
  background-color: @color-black-005;

  .force-aspect-ratio(@ratio);
  &.aspectRatio4_3 {
    .force-aspect-ratio(0.75);
  }

  img {
    margin: 0;
    width: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent center center no-repeat;
    background-size: @icon-size @icon-size;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 40px !important;
      width: 40px !important;
      color: @color-black-050;
    }
  }
}

.carouselContainer {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  border-radius: 16px;

  .carouselPhoto {
    border-radius: 0;
  }
}
