@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

@list-item-border: 1px solid @color-alto;

.linksListContainer {
  margin: @spacing-md 0;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .listItem {
    &:first-of-type {
      border-top: @list-item-border;
    }

    border-bottom: @list-item-border;

    .listItemLink {
      display: flex;
      align-items: center;
      gap: @spacing-sm;
      cursor: pointer;
      color: @color-link-jewel-blue;
      font-size: @zui-fontsize-small;
      padding: @spacing-sm 0;
      text-decoration-line: none;
      text-transform: none;
    }
  }
}
