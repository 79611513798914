@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.realWeddingCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  .photo {
    cursor: pointer;
  }
}

.hed {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .not-desktop({
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  });
}
