@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';

.stackedSearchbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 0;
  padding: @spacing-md;
  background-color: @color-white;
  height: 100%;
  width: 375px;
  max-width: 1200px;
  gap: @spacing-sm;

  .mobile-xs({
    width: 100%;
  });

  .hed {
    margin: 0;
    text-align: center;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .input {
      position: relative;
    }
  }

  .button {
    width: 100%;
    .not-desktop({
      transition: none;
    });
  }

  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: @spacing-24;
    align-self: stretch;
    .desktop({
      flex-direction: column;
    });

    .quizBtn {
      padding: 10px @spacing-24;
      display: none;
      .desktop({
        display: block;
      });
    }

    .savedSearchesButton {
      width: 100%;
    }

    .mobile({
      margin-top: 12px;
    });
  }
}

.searchButton {
  flex-grow: 1;
  width: 100%;
}
