/* stylelint-disable css-modules/no-global-scoped-selector */
@import (reference) '~/stylesheets/core.less';

.root {
  --rdp-cell-size: 32px;
  --rdp-accent-color: @color-black-100;
  --rdp-background-color: @color-black-100;

  margin: 0;
}

.caption {
  margin-bottom: 0;
  display: table-caption;
  padding: 0 0.5em;
  text-align: left;
}
.captionLabel {
  && {
    --rdp-background-color: transparent;

    text-align: left;
    font-weight: @proxima-semi-bold;
    font-size: 18px;
    color: #505050;

    .mobile({
      font-size: @zui-fontsize-small;
    });
  }
}

.dropdown {
  &:focus-visible {
    background-color: initial;
  }
}

.month {
  display: table;
  margin: 0;
  border-collapse: separate;
  border-spacing: 2px;
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 1em;
  position: relative;
  user-select: none;
}

.headCell {
  text-transform: none;
  color: #8b9898;
  display: table-cell;
  font-size: 0.875em;
  padding: 0.5em 0;
  text-align: center;
  font-weight: @font-weight-regular;

  abbr {
    text-decoration: none;
  }
}

.rangePickerDay {
  font-weight: 100;
}

.dayRange {
  &:hover {
    background-color: var(--rdp-accent-color) !important;
  }
}

.dayRangeStart {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.dayRangeMiddle {
  --rdp-background-color: @color-black-015;
  --rdp-accent-color: @color-black-015;

  color: @color-black-100;
}

.day {
  border: 0;
  outline: none;
  font-weight: @font-weight-regular;

  &--today {
    color: @color-black-100;
  }

  &--outside {
    visibility: hidden;
  }

  &--booked {
    text-decoration: line-through;
    background-color: @color-background-gray-2;
    color: @color-text-gray;
    font-weight: @font-weight-regular;
  }

  &:hover {
    --rdp-background-color: @color-black-015;
  }
}

.daySelected {
  background-color: var(--rdp-background-color);
}
.overlayWrapper {
  position: relative;
}
.overlay {
  width: 550px;
  .mobile({
      width: 281px;
    });

  border: 2px solid @color-black-030;
  border-radius: @spacing-xxs;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  left: 0;
  position: absolute;
  z-index: 1;

  &.withoutButtons {
    padding: @spacing-sm;
    width: auto;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: @spacing-xs;
  margin-right: @spacing-xs;
}

.previous {
  &&&& {
    left: -20px;
    color: @color-black-100;
    background-color: @color-white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: none;
    box-shadow: @default-box-shadow;
  }
}
.next {
  &&&& {
    right: -20px;
    color: @color-black-100;
    background-color: @color-white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: none;
    box-shadow: @default-box-shadow;
  }
}

.cell {
  :global {
    // In firefox, with the calendar, for some reason, months that start on
    // anyday other than sunday, the first row was 53px instead of 32px tall.
    // This targets firefox and sets the gricell height to 32px
    [role='gridcell'] {
      @supports (-moz-appearance: none) {
        height: var(--rdp-cell-size);
      }
    }
  }
}
