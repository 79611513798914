@import (reference) '~/stylesheets/core.less';

.videoSection {
  height: 575px;
  background-color: @color-black;

  @media (max-width: @screen-sm-max) {
    height: calc(~'100vw * 0.5625');
    background-color: @color-white;
  }
  @media (max-width: @screen-md-max) {
    margin-bottom: 0;
  }
}

.sectionHr {
  margin-top: @spacing-lg;
  margin-bottom: @spacing-lg;
}
