@import (reference) '~@zola/zola-ui/src/common.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';

.shareButtonContainer {
  .icon {
    margin-right: @spacing-xs;
  }

  .transparentButton {
    &:hover {
      color: @color-black-100;
      background-color: @color-white;
    }
  }

  .shareButtonCompact {
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 50%;
  }
}

.icon {
  margin-right: @spacing-xs;
}
