@import (reference) '~/stylesheets/core.less';

.marketplace__see-all-drawer {
  .mt-primary {
    margin-top: 30px;
  }
  .see-all-drawer__control {
    display: flex;
    justify-content: space-between;
    .see-all-drawer__button {
      display: flex;
      align-items: center;
      white-space: nowrap;
      @media (max-width: @screen-xs-max) {
        &:hover {
          border-bottom: 0;
        }
      }
    }
  }
}
