@import (reference) '~/stylesheets/core.less';

.marketplace__storefront {
  font-size: @font-size-p-small-mobile;

  .container {
    padding-top: 0;
    padding-left: unset;
    padding-right: unset;
    margin-left: unset;
    margin-right: unset;
    width: auto;

    @media (min-width: @screen-xl-min) {
      width: 100%;
      max-width: @screen-xl - 20px;
    }
  }

  .inlineText {
    display: inline;
  }

  .storefront__section {
    .container {
      padding-top: 0;
      padding-left: unset;
      padding-right: unset;
      margin-left: unset;
      margin-right: unset;
      width: auto;

      @media (min-width: @screen-xl-min) {
        width: 100%;
        max-width: @screen-xl - 20px;
      }

      & > hr {
        margin: @spacing-24 0;
      }
    }
  }
}
