@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/util/_type.module.less';

.modal {
  width: 570px;
  max-width: 100vw;
  padding: @spacing-lg;
  display: flex;
  flex-direction: column;

  .not-desktop-lg({
    padding: @spacing-md;
  });
  .hed {
    .regular-heavy();

    color: @color-black;
    margin-bottom: @spacing-md;
    .mobile({
      .large-heavy();
    });
  }

  .dek {
    margin-bottom: @spacing-md;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    .mobile({
      width: 300px;
      flex-direction: column;
    });
  }
  .required {
    color: @color-red;
  }
}

.bookingWindowModal {
  .content {
    min-height: 252px;
    .mobile({
      min-height: 76vh;
      margin-top: 25px;
    });
  }
  .dropdowns {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    :global {
      .dropdown-v2__selection-box.no-option-selected:not(.expanded) {
        color: @color-text-gray;
      }
      .dropdown-v2__container {
        .mobile({
          .dropdown-v2__options {
            height: 79vh;
            .dropdown-v2__option {
              margin-bottom: @spacing-xxs;
            }
          }
          .dropdown-v2__options-mobile-header {
            margin: 25px @spacing-md 0;
            border-bottom-width: 1px;
            text-align: left;
            color: @color-black;
            .large-heavy();
          }
          .dropdown-v2__options-wrapper {
            margin-left: @spacing-md + @spacing-sm;
            margin-top: @spacing-md + @spacing-sm;
          }
        });
      }
    }
    .availability {
      margin-bottom: @spacing-md;
    }
    .month {
      margin-right: @spacing-md;
    }
    .year {
      min-width: 110px;
      :global {
        .v2-render-field .input-field__container {
          width: 110px;
        }
      }
    }
  }
  .buttons {
    .not-mobile({
      .cancel {
        margin-right: @spacing-sm;
      }
    });
    .mobile({
      flex-direction: column-reverse;
      width: 89vw;
      .cancel {
        margin-top: @spacing-sm;
      }
    });
  }
}
