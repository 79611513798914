@import (reference) '~/stylesheets/core.less';

.render-field {
  .DayPicker {
    color: @color-black;
    font-size: @font-size-base;
    font-weight: @font-weight-light;
  }

  .DayPicker-NavButton {
    &--prev {
      top: 1.2em;
      right: 50px;
    }

    &--next {
      top: 1.2em;
      right: 20px;
    }
  }

  .DayPicker-Weekday {
    padding-left: 0;
    padding-right: 0;
    color: @color-secondary-gray;
    font-weight: @font-weight-regular;
  }

  .DayPicker-Day {
    width: 35px;
    height: 35px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    outline: none;

    &:hover {
      background-color: @color-tertiary-background-blue !important;
    }

    &--today {
      color: @color-black;
      font-weight: @font-weight-light;
    }

    &--outside {
      color: @color-outline-gray;
    }

    &--selected,
    &--selected:hover {
      color: @color-white !important;
      background-color: @color-navy !important;
    }
  }

  .DayPickerInput {
    display: block;
  }
  .daypicker__container.with-icon {
    input {
      padding-left: 30px;
    }
  }
  .input-addon {
    width: @spacing-md;
    height: @spacing-md;
    position: absolute;
    bottom: 25%;
    left: 5px;
  }
}
