@import (reference) '../core.less';

.marketplace-top-nav() {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: @color-white;
  z-index: @zIndex-content-top;
  border-bottom: 1px solid @color-black-030;
  padding-left: @primary-spacing;
  padding-right: @primary-spacing;
  top: 0;
  min-height: 70px;
  .nav__page-title {
    display: flex;
    align-items: center;
    .nav__section-icon {
      height: 53px;
      width: 53px;
      margin-right: @tertiary-spacing;
    }
    .nav__section-title {
      font-weight: @font-weight-bold;
      font-size: @font-size-h3;
      font-family: @font-family-base;
    }
  }
  .nav__edge-element {
    flex: 1;
    &:last-child {
      text-align: right;
    }
    a,
    button {
      text-transform: none;
    }
  }
  .nav__back-link {
    font-weight: @font-weight-bold;
    font-size: @font-size-base;
  }
  @media (max-width: @screen-xs-max) {
    padding-left: @tertiary-spacing;
    padding-right: @tertiary-spacing;
  }
}
