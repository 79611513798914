@import (reference) '~@zola/zola-ui/src/styles/common/_layout.less';
@import (reference) '~@zola/zola-ui/src/common.less';
@import (reference) '~/stylesheets/common/mixins.less';

.vendorBreadcrumbs {
  padding: @spacing-16 @spacing-md;

  .not-mobile({
    padding: @spacing-16 0 @spacing-md;
  });
}
