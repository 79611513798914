@import (reference) '~/stylesheets/core.less';

.card {
  display: flex;
  flex-direction: column;
  padding: @spacing-md;
  margin: 8px 0;
  background: @color-white;
  border: 1px solid #eee;
  box-shadow: @default-box-shadow;
  min-height: 160px;

  .hed {
    margin-bottom: 10px;
  }

  .note {
    margin: 4px 0;
  }

  .link {
    line-height: @zui-fontsize-small;
    margin-top: @spacing-sm;
    text-align: left !important;
  }
}
