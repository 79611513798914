@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/stylesheets/common/mixins.less';

section.section {
  .sectionContent {
    .container-mixin();

    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: @screen-xs-max) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &.noPadding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.stretchToEdge {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &.preAuth {
    .sectionContent {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;

      &.stretchToEdge {
        @media (min-width: 1922px) {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
    }
  }
}
