@import '~@zola/zola-ui/src/styles/common/_variables.less';
// @import '../../../node_modules/slick-carousel/slick/slick.css';

.carousel__container {
  max-width: 100%;
  padding: 0 35px;

  /* use flexbox on smaller (mobile) screens */
  @media (max-width: @screen-sm) {
    .slick-slider {
      .slick-track {
        display: flex;
        .slick-slide {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  //.slick-initialized .slick-slide {
  //  display: none;
  //  &.slick-active {
  //    display: block;
  //  }
  //}

  // custom dots
  .slick-dots {
    margin-top: 30px;
    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
    }
    button.carousel-dot__container {
      border: none;
      padding: 0;
      margin: 0;
      vertical-align: middle;
      cursor: pointer;
      background-color: transparent;
    }
    .carousel-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: @color-white;
      border: 1px @color-secondary-gray solid;
      &:hover {
        border-color: @color-hover-teal;
        background-color: @color-hover-teal;
      }
    }
    .slick-active {
      .carousel-dot {
        width: 15px;
        height: 15px;
        background-color: @color-primary-teal;
        border: none;
      }
    }
  }

  // custom arrows
  .slick-prev {
    position: absolute;
    top: 50%;
    left: -35px;
    transform: translateY(-50%);
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  }
  .slick-next {
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  }

  button.slick-disabled,
  button.slick-disabled:hover {
    background-color: @color-outline-gray;
    color: @color-primary-gray;
    border-color: @color-outline-gray;
    cursor: default;
  }
}
