@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/components/common/zolaUI/renderDatePicker/daypicker.module.less';

.availability {
  .months();

  justify-content: center;
  gap: 24px;

  :global {
    .rdp-nav_button_next,
    .rdp-nav_button_previous {
      &:hover {
        background-color: @color-black-005 !important;
      }
    }
  }
}

.hed {
  display: flex;
  justify-content: space-between;

  button {
    .mobile({
      display: none;
    });
  }
}

.wrapper {
  position: relative;
}

.overlay {
  opacity: 0.4;
}

.overlayButton {
  width: 200px;
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 100px);
}
