@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~/stylesheets/core.less';
@import (reference) '~/stylesheets/common/mixins.less';

.serviceSection {
  .image {
    width: 80px;
  }

  .heading {
    margin-top: 32px;
    margin-bottom: 8px;
  }
}
