@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.socialProof {
  background-color: @color-mint;
  padding: @spacing-md;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: @spacing-md;
  border-radius: @spacing-sm;
  .dek {
    color: @color-forest-100;
    font-weight: @font-weight-semi-bold;
    margin-left: @spacing-10;
  }
}

span.tooltipContainer {
  position: relative;
  margin-left: @spacing-xs;
  width: 100%;
  display: grid;
}

span.tooltipIcon {
  vertical-align: middle;
  line-height: 16px;

  :global {
    .tooltip__container {
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;

      // this is something like the height of the button 44px + 1px spacing + the height of the caret 9px
      bottom: 54px;
      white-space: normal;
      width: 160px;
      z-index: @zIndex-content-top;
    }
  }
}
