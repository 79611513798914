@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_layout.less';

.vendorHeader {
  .hed {
    font-weight: @proxima-semi-bold;
    font-size: @zui-fontsize-large;
    margin: @spacing-md 0;
  }
  .vendor {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: @spacing-md;
    background-color: #ebf4f9;
    border-radius: 4px;
    padding: @spacing-sm;

    img {
      width: 80px;
      height: 80px;
      border-radius: 4px;
    }

    .info {
      .name {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
}
