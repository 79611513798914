@import (reference) '~/stylesheets/core.less';

.marketplace__section-drawer {
  .section-drawer__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
  }
  .section-drawer__body-container {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease;
  }
  &.open {
    .section-drawer__body-container {
      max-height: 100%;
    }
  }
}
